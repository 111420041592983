import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

@Injectable({
	providedIn: 'root',
})
export class ExcelService {
	constructor() {}

	generateExcel(data: any[], fileName: string, headers: string[]): void {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Sheet 1');

		// Add headers
		worksheet.addRow(headers);

		const headersLabel = Object.keys(data[0]).filter((item) => item.toLowerCase() !== 'id');

		// Add data
		data.forEach((item) => {
			const row = [];
			headersLabel.forEach((label) => {
				row.push(item[label]);
			});
			worksheet.addRow(row);
		});

		// Save the workbook to a blob
		workbook.xlsx.writeBuffer().then((buffer) => {
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(blob, `${fileName}.xlsx`);
		});
	}
}
